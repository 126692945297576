import React from "react";
import "../css/Footer.css";

const Footer = (props) => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="footer-infos col-lg-6">
                        <div className="footer-infos-column">
                            <p className="footer-title">Adresse</p>
                            <p>Parc Sportif du Léopard</p>
                            <p>76 rue du Coquillage</p>
                            <p>50170 Le Mont-Saint-Michel</p>
                        </div>
                        <div className="footer-infos-column">
                            <p className="footer-title">Contact</p>
                            <p>football@unionsaintmichel.fr</p>
                            <p>
                                <a href="https://twitter.com/rayurart" target="_blank" rel="noreferrer">
                                    Twitter
                                </a>
                            </p>
                            <p>02 35 01 23 45</p>
                        </div>
                    </div>
                    <div className="footer-usm col-lg-6">
                        <div className="footer-usm-content">
                            <img
                                className="footer-logo"
                                alt="Logo USM"
                                src={process.env.PUBLIC_URL + "/images/Logo_USM.png"}
                            />
                            <p className="footer-logo-title">In Divitibus Credimus</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
