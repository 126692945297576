import React, { useEffect } from "react";
import "../css/Goals.css";
import Footer from "./Footer";
import Menu from "./Menu";
import ScrollReveal from "scrollreveal";

const Goals = (props) => {
    useEffect(() => {
        let sr = ScrollReveal();

        sr.reveal(".js--fadeInLeft", {
            origin: "left",
            distance: "100px",
            easing: "ease-in-out",
            duration: 800,
        });

        sr.reveal(".js--fadeInRight", {
            origin: "right",
            distance: "100px",
            easing: "ease-in-out",
            duration: 800,
        });
    }, []);

    const timelineItems = [
        {
            id: 1,
            title: "Arrivée des fondateurs",
            date: "Avril 2013",
            description:
                "En 2013, un consortium d'entreprises originaires de la Normandie décide d'enrichir l'aspect touristique, et de développer le paysage sportif de la région. Il choisit de s'appuyer sur le monument le plus visité de France, le Mont Saint-Michel, pour commencer sur des bases solides.",
            image: process.env.PUBLIC_URL + "/images/owners.png",
            imageContain: false,
            bgColor: 'var(--secondary-color)'
        },
        {
            id: 2,
            title: "L'Union Saint Michel voit le jour",
            date: "Décembre 2018",
            description:
                "En référence à sa ville et sa célèbre abbaye sur une île, le nom de l'Union Saint-Michel voit le jour. Ce nom doit matéraliser la valeur principale voulue par le club : l'Union.",
        },
        {
            id: 3,
            title: "Création du logo",
            date: "Janvier 2021",
            description:
                "Qui dit club de foot, dit bien évidemment logo du club. Un logo est une identité, un visage, la représentation du club que les supporters vont pouvoir aimer.",
            image: process.env.PUBLIC_URL + "/images/Logo_USM.png",
            imageContain: true,
            bgColor: 'var(--secondary-color)'
        },
        {
            id: 4,
            title: "Le Camp Moule",
            date: "Avril 2023",
            description:
                "Près de 9 ans après le début des travaux, le stade du futur club du Mont Saint-Michel est terminé. En référence à la gastronomie locale, le Camp Moule est un stade luxueux et ultra moderne, réalisé dans des temps records et dans le respect de l'environnement.",
            image: process.env.PUBLIC_URL + "/images/stade_ext.jpg",
        },
        {
            id: 5,
            title: "L'Union Saint Michel est créée",
            date: "Avril 2023",
            description:
                "Le club du Mont Saint-Michel est officiellement créé, 10 ans après que l'idée de créer un club a émergé.",
        },
        {
            id: 6,
            title: "Montée en National 2",
            date: "2024",
            description:
                "La première saison de l'Union Saint-Michel doit être le moteur qui lancera toutes les suivantes, avec un objectif clair de monter en National 2 lors de sa première saison. Les ligues Normandes font partie des plus difficiles du pays, mais l'union fait la force.",
            image: process.env.PUBLIC_URL + "/images/national2.svg",
            imageContain: true,
        },
        {
            id: 7,
            title: "1er club formateur français",
            date: "2024",
            description:
                "Le vivier français est si grand, que l'Union Saint-Michel se doit de fonder sa progression sur la formation et l'accompagnement des jeunes. C'est pour cette raison que les infrastructures du club font d'ores et déjà parti des meilleures de France.",
        },
        {
            id: 8,
            title: "Montée en Ligue 1",
            date: "2030",
            description:
                "L'élite française représente l'un des objectifs les plus importants pour le club. Son accession en L1 ne doit cependant qu'être une étape pour voir plus grand.",
            image: process.env.PUBLIC_URL + "/images/ligue1.png",
            imageContain: true,
        },
        {
            id: 9,
            title: "USM devient une marque déposée",
            date: "2033",
            description:
                "En tentant de devenir l'une des meilleures équipes de France et d'Europe, l'USM se doit de porter la marque du club au delà des frontières. Le Mont Saint-Michel ne sera plus connu que pour l'histoire de son abbaye, mais également pour l'histoire de son club.",
        },
        {
            id: 10,
            title: "L'USM remporte la Ligue des Champions",
            date: "2035",
            description:
                "Elle est le rêve de tout footballeur, elle l'est également pour l'Union Saint-Michel. C'est une montagne qui attend notre club pour arriver à son but ultime, mais rien n'est impossible lorsque nous croyons en la richesse de l'union.",
            image: process.env.PUBLIC_URL + "/images/ldc.png",
        },
    ];

    return (
        <div id="objectifs">
            <Menu />
            <section className="goals">
                <h1>Historique et Objectifs</h1>
            </section>
            <section className="timeline">
                <div className="container">
                    {timelineItems.map((timelineItem) => {
                        let classTimelineContent = "timeline-content " + (timelineItem.id % 2 !== 0 ? "js--fadeInLeft " : "js--fadeInRight ");
                        if (timelineItem.image) {
                            classTimelineContent += "timeline-card ";
                        }

                        let styleImage = {
                            backgroundImage: `url('${timelineItem.image}')`,
                            backgroundColor: timelineItem.bgColor ?? 'var(--primary-color)',
                            backgroundSize: timelineItem.imageContain ? 'contain' : 'cover',
                        }

                        return (
                            <div key={timelineItem.id} className="timeline-item">
                                <div className="timeline-img"></div>

                                <div
                                    className={classTimelineContent}
                                >
                                    {timelineItem.image ? (
                                        <div
                                            className="timeline-img-header"
                                            style={styleImage}
                                        >
                                            <h2>{timelineItem.title}</h2>
                                        </div>
                                    ) : (
                                        <h2>{timelineItem.title}</h2>
                                    )}
                                    <div className="date">{timelineItem.date}</div>
                                    <p>{timelineItem.description}</p>
                                </div>
                            </div>
                        );
                    })}
                </div>
            </section>
            <Footer />
        </div>
    );
};

export default Goals;
