import React, { useCallback, useEffect, useState } from "react";
import Menu from "./Menu";
import "../css/Roulette.css";
import * as d3 from "d3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as bootstrap from 'bootstrap';

const Roulette = (props) => {
    // Base Variables (wheels, possibilities etc..)
    const [wheels, setWheels] = useState([]);
    const [currentWheel, setCurrentWheel] = useState();
    const [possibilities, setPossibilities] = useState([]);
    const [mappingPossibilities, setMappingPossibilities] = useState([]);
    const timeRoulette = 10;

    // Variables used for active wheel configuration
    const [rotation, setRotation] = useState(0);
    const [resetMode, setResetMode] = useState(false);
    const [rouletteContainerWidth, setRouletteContainerWidth] = useState(0);
    const [rouletteRun, setRouletteRun] = useState(false);
    const [rouletteRunEnded, setRouletteRunEnded] = useState(false);

    // Variable for theme
    const [theme, setTheme] = useState(localStorage.getItem('rouletteTheme') ? localStorage.getItem('rouletteTheme') : 'fcb');

    // Variables for the win
    const [modalWinner, setModalWinner] = useState();
    const [winner, setWinner] = useState();

    const [modalFactory, setModalFactory] = useState();

    // Audio
    const localStorageSound = localStorage.getItem('rouletteSound');
    const audios = [
        {
            id: 1,
            name: 'Roulette',
            path: 'wheel.mp3',
            isStoppingAtEnd: true,
        },
        {
            id: 2,
            name: 'Allez !',
            path: 'allez.mp3',
            isStoppingAtEnd: false,
        },
        {
            id: 3,
            name: 'J\'arrête FM !',
            path: 'arrete.mp3',
            isStoppingAtEnd: true,
        }
    ];
    
    const localStorageAudio = audios.find(audioEl => {
        return audioEl.id === parseInt(localStorageSound);
    });

    const [audioId, setAudioId] = useState(localStorageSound ? parseInt(localStorageSound) : 0);
    const [audioPlayer, setAudioPlayer] = useState(localStorageAudio ? new Audio(process.env.PUBLIC_URL + "/sounds/" + localStorageAudio.path) : null);

    const fetchWheels = useCallback((forceFetch = false) => {
        fetch("./config/config.json")
            .then((response) => response.json())
            .then((data) => {
                if (forceFetch || localStorage.getItem('rouletteWheels') === null) {
                    localStorage.setItem('rouletteWheels', JSON.stringify(data.wheels));
                }
                let wheels = JSON.parse(localStorage.getItem('rouletteWheels'));
                setWheels(wheels);
                setActiveWheel(wheels[0]);
            })
    }, [])

    useEffect(() => {
        fetchWheels();
    }, [fetchWheels]);

    useEffect(() => {
        setModalWinner(new bootstrap.Modal(document.getElementById('winnerModal'), {}));
        setModalFactory(new bootstrap.Modal(document.getElementById('factoryModal'), {}));

        function handleResize() {
            let wrap = document.getElementById("wheel");
            wrap.style.flex = 1;
            setRouletteContainerWidth(wrap.offsetWidth < wrap.offsetHeight ? wrap.offsetWidth : wrap.offsetHeight);
            wrap.style.flex = 0;

            const themesColors = {
                usm: ['var(--primary-color)', 'var(--secondary-color)'],
                fcb: ['var(--primary-fcb)', 'var(--secondary-fcb)'],
                rco: ['var(--primary-rco)', 'var(--secondary-rco)'],
            }

            let colors = themesColors[theme];

            let currentMapping = [];
            possibilities.forEach((possibility, index) => {
                for (let i = 0; i < possibility.coeff; i++) {
                    let possibilityMap = { ...possibility };
                    possibilityMap.id = index;
                    possibilityMap.coeff = 1;
                    currentMapping.push(possibilityMap);
                }
            });

            for (let i = currentMapping.length - 1; i > 0; i--) {
                const j = Math.floor(Math.random() * (i + 1));
                const temp = currentMapping[i];
                currentMapping[i] = currentMapping[j];
                currentMapping[j] = temp;
            }

            setMappingPossibilities(currentMapping);

            if (currentMapping.length % 2 !== 0) {
                colors.push("#fff");
            }

            let color = d3.scaleOrdinal(colors);

            let pie = d3.pie().value(function (d) {
                return d.coeff;
            });

            let radius = Math.min(rouletteContainerWidth, rouletteContainerWidth) / 2 - 10;
            let arcGenerator = d3.arc().innerRadius(0).outerRadius(radius);

            let svg = d3
                .select("#wheel svg")
                .html("")
                .attr("width", rouletteContainerWidth)
                .attr("height", rouletteContainerWidth)
                .append("g")
                .attr("transform", "translate(" + rouletteContainerWidth / 2 + "," + rouletteContainerWidth / 2 + ")");

            svg.selectAll("arc")
                .data(pie(currentMapping))
                .enter()
                .append("path")
                .attr("d", arcGenerator)
                .attr("fill", function (d, i) {
                    return color(i);
                })
                .attr("stroke", "black")
                .style("stroke-width", "2px")
                .style("opacity", 0.8);

            svg.selectAll("arc")
                .data(pie(currentMapping))
                .enter()
                .append("text")
                .text(function (d) {
                    return d.data.name;
                })
                .attr("transform", function (d) {
                    d.angle = (d.startAngle + d.endAngle) / 2;
                    let rotation =
                        d.endAngle < Math.PI
                            ? ((d.startAngle / 2 + d.endAngle / 2) * 180) / Math.PI
                            : ((d.startAngle / 2 + d.endAngle / 2 + Math.PI) * 180) / Math.PI;
                    return "translate(" + arcGenerator.centroid(d) + ") rotate(-90) rotate(" + rotation + ")";
                })
                .attr("text-anchor", "middle")
                .attr('class', 'roulette-text')
                .style("font-weight", "bold")
                .attr("y", '6')
                .style("fill", function (d) {
                    return currentMapping.length % 2 === 0 || (d.index + 1) % colors.length !== 0 ? "white" : "black";
                });
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [possibilities, rouletteContainerWidth, theme]);

    const setActiveWheel = (wheel) => {
        if (wheel) {
            setCurrentWheel(wheel.id);
            setPossibilities(wheel.possibilities);
        } 
    };

    const getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min) + min);
    };

    const handleClickRoulette = () => {
        if (rouletteRunEnded) {
            handleResetRoulette();
            return;
        }

        // Sound effect
        if (audioPlayer) {
            audioPlayer.play();
        }

        setRouletteRun(true);
        setRouletteRunEnded(false);
        let rotationAngle = getRandomInt(5000, 10000);
        setRotation(rotationAngle);

        // Calcul du vainqueur
        let angle = 360 / mappingPossibilities.length;
        let lastAngle = rotationAngle % 360;
        let index = Math.floor(lastAngle / angle);
        let indexWinner = mappingPossibilities.length - index - 1;

        setTimeout(() => {
            modalWinner.show();
            setRouletteRun(false);
            setRouletteRunEnded(true);
        }, timeRoulette * 1000 + 500);

        let winner = mappingPossibilities[indexWinner].name;
        setWinner(winner);
    };

    const handleResetRoulette = () => {
        setResetMode(true);

        setRotation(0);

        setTimeout(() => {
            setResetMode(false);
            setRouletteRun(false);
            setRouletteRunEnded(false);
            modalWinner.hide();
        }, 100);
    };

    const handleFactoryRoulette = () => {
        fetchWheels(true);
        modalFactory.hide();
        document.getElementsByClassName('modal-backdrop')[0].remove();
    }

    const handleClickChooseRoulette = (id) => {
        setResetMode(true);

        setRotation(0);

        setTimeout(() => {
            let newWheel = wheels.find((x) => x.id === id);
            setActiveWheel(newWheel);
            setResetMode(false);
            setRouletteRun(false);
            setRouletteRunEnded(false);
        }, 100);
    };

    const handleChangeTheme = (e) => {
        let themeCode = e.currentTarget.dataset.theme;
        localStorage.setItem('rouletteTheme', themeCode);
        setTheme(themeCode);
    };

    const handleChangeSound = (e) => {
        let audioID = parseInt(e.currentTarget.dataset.sound);
        setAudioId(audioID);
        localStorage.setItem('rouletteSound', audioID);
        let audioToUse = audios.find(audioEl => {
            return audioEl.id === audioID;
        })
        setAudioPlayer(audioToUse ? new Audio(process.env.PUBLIC_URL + "/sounds/" + audioToUse.path) : null);
    }

    const styleRoulette = {
        transition: "transform " + (resetMode ? 0 : timeRoulette) + 's cubic-bezier(0.0, 0.15, 0.3, 1)',
        transform: "rotate(" + rotation + "deg)",
    };

    const savePossibilities = (possibilities) => {
        let newWheels = wheels.map(wheel => {
            if (wheel.id === currentWheel) {
                return {...wheel, possibilities: possibilities}
            }
            return wheel;
        });
        localStorage.setItem('rouletteWheels', JSON.stringify(newWheels));
        setWheels(newWheels);
        setPossibilities(possibilities);
    }

    const saveWheels = (wheels) => {
        localStorage.setItem('rouletteWheels', JSON.stringify(wheels));
        setWheels(wheels);
    }

    const addCoeffToPossibility = (id) => {
        let newPossibilities = possibilities.map(possibility => {
            if (possibility.id === parseInt(id)) {
                return {...possibility, coeff: possibility.coeff + 1}
            }
            return possibility;
        })
        savePossibilities(newPossibilities);
    }

    const handleClickLessCoeff = (e) => {
        let possibilityId = e.currentTarget.closest('li').dataset.possibility;
        let hasChanged = false;
        let newPossibilities = possibilities.map(possibility => {
            if (possibility.id === parseInt(possibilityId) && possibility.coeff > 0) {
                hasChanged = true;
                return {...possibility, coeff: possibility.coeff - 1}
            }
            return possibility;
        })
        if (hasChanged) {
            savePossibilities(newPossibilities);
        }
    };

    const handleClickMoreCoeff = (e) => {
        let possibilityId = e.currentTarget.closest('li').dataset.possibility;
        addCoeffToPossibility(possibilityId);
    };

    const handleClickDeletePossibility = (e) => {
        let possibilityId = e.currentTarget.closest('li').dataset.possibility;
        let newPossibilities = possibilities.filter(possibility => {
            return possibility.id !== parseInt(possibilityId);
        })
        savePossibilities(newPossibilities);
    };

    const handleClickDeleteWheel = (e) => {
        let wheelId = e.currentTarget.closest('li').dataset.wheel;
        let newWheels = wheels.filter(wheel => {
            return wheel.id !== parseInt(wheelId);
        })
        saveWheels(newWheels);
    };

    const handleKeyDownPossibility = (e) => {
        let value = e.currentTarget.value;
        if (e.key === 'Enter' && value.length !== 0) {
            let possibilityWithSameName = possibilities.filter(possibility => {
                return possibility.name === value;
            });

            if (possibilityWithSameName.length !== 0) {
                addCoeffToPossibility(possibilityWithSameName[0].id);
            } else {
                let lastElement = possibilities.at(-1);
                let lastId = lastElement ? lastElement.id : 0;
                savePossibilities([...possibilities, {id: lastId + 1, name: value, coeff: 1}]);
            }
            e.currentTarget.value = '';
        }
    }

    const handleKeyDownWheel = (e) => {
        let value = e.currentTarget.value;
        if (e.key === 'Enter' && value.length !== 0) {
            let rouletteWithSameName = wheels.filter(wheel => {
                return wheel.name === value;
            });

            if (rouletteWithSameName.length === 0) {
                let lastId = wheels.at(-1).id;
                saveWheels([...wheels, {id: lastId + 1, name: value, icon: "futbol", possibilities: []}]);
            }
            e.currentTarget.value = '';
        }
    }

    return (
        <div id="roulette">
            <Menu />
            <div className="roulette-container">
                <h1>La Raylette</h1>
                <div className={"roulette-content row theme-" + theme}>
                    <div className="col-12 col-lg-3">
                        <div className="roulette-column">
                            <p className="roulette-column-title">Themes</p>
                            <div className="px-3 mb-3 d-flex justify-content-between">
                                <button data-theme='fcb' className={"btn w-25 mx-2 " + (theme === 'fcb' ? "btn-secondary-" + theme : "btn-primary-" + theme)} onClick={handleChangeTheme}>FCB</button>
                                <button data-theme='usm' className={"btn w-25 mx-2 " + (theme === 'usm' ? "btn-secondary-" + theme : "btn-primary-" + theme)} onClick={handleChangeTheme}>USM</button>
                                <button data-theme='rco' className={"btn w-25 mx-2 " + (theme === 'rco' ? "btn-secondary-" + theme : "btn-primary-" + theme)} onClick={handleChangeTheme}>RCO</button>
                            </div>

                            <p className="roulette-column-title">Sons</p>
                            <div className="px-3 mb-3 d-flex justify-content-between flex-wrap">
                            <button data-sound="0" className={"btn my-1 flex-1 " + (audioId === 0 ? "btn-secondary-" + theme : "btn-primary-" + theme)} style={{width: "48%"}} onClick={handleChangeSound}>
                                Désactivé
                                <FontAwesomeIcon className="ms-2" icon="volume-xmark" />
                            </button>
                                {audios.map(audioEl => {
                                    return <button key={audioEl.id} data-sound={audioEl.id} className={"btn my-1 flex-1 " + (audioId === audioEl.id ? "btn-secondary-" + theme : "btn-primary-" + theme)} style={{width: "48%"}} onClick={handleChangeSound}>{audioEl.name}</button>
                                })}
                            </div>

                            <div className="roulette-column-title">Roulettes</div>
                            <div className="px-3">
                                <div className="roulette-wheels-add">
                                        <label htmlFor="roulette-wheels-input" className="form-label">Une roue tourne doit tourner...</label>
                                        <input type="text" className="form-control" id="roulette-wheels-input" readOnly={rouletteRun} disabled={rouletteRun} onKeyDown={handleKeyDownWheel} />
                                </div>
                                <ul className="roulettes-items">
                                    {wheels.map((wheel) => {
                                        return (
                                            <li key={wheel.id} className="d-flex mb-2" data-wheel={wheel.id}>
                                                <button
                                                    className={`btn btn-${(wheel.id === currentWheel ? 'secondary' : 'primary')}-${theme} w-100`}
                                                    onClick={() => handleClickChooseRoulette(wheel.id)}
                                                    disabled={rouletteRun}
                                                >
                                                    {wheel.name}
                                                    <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon={wheel.icon} />
                                                </button>
                                                <button className={`btn ms-2 btn-secondary-${theme}`} onClick={handleClickDeleteWheel}><FontAwesomeIcon icon="xmark" /></button>
                                            </li>
                                        );
                                    })}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-6 d-flex flex-column">
                        <div className="roulette-main">
                            <div className="d-flex justify-content-between">
                                <button className={`btn btn-primary-${theme} align-self-start my-2`} data-bs-toggle="modal" data-bs-target="#factoryModal">
                                    Par défaut
                                    <FontAwesomeIcon style={{ marginLeft: "0.5rem" }} icon="gear" />
                                </button>
                            </div>
                            <div id="wheel" className={ mappingPossibilities.length === 0 ? 'invisible' : ''}>
                                <svg id="svg-wheel" style={styleRoulette}></svg>
                                <div className="markers"></div>
                                <button
                                    type="button"
                                    id="btn-start"
                                    onClick={handleClickRoulette}
                                    disabled={rouletteRun}
                                >
                                    {rouletteRunEnded ? "Réinitialiser" : "Tourner !"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-3">
                        <div className="roulette-column">
                            <p className="roulette-column-title">Configuration</p>
                            <ul className="roulette-config-list">
                                <div className="roulette-config-add">
                                    <label htmlFor="roulette-config-input" className="form-label">Qu'est-ce qu'on ajoute ?</label>
                                    <input type="text" className="form-control" id="roulette-config-input" readOnly={rouletteRun} disabled={rouletteRun} onKeyDown={handleKeyDownPossibility} />
                                </div>
                                {possibilities.map((possibility) => {
                                    return <li data-possibility={possibility.id} key={possibility.id}>
                                        <div className="fw-bold">{possibility.name}</div>
                                        <div className="roulette-config-element">
                                            <button className={`btn btn-primary-${theme}`} disabled={rouletteRun} onClick={handleClickLessCoeff}><FontAwesomeIcon icon="minus" /></button>
                                            <div style={{minWidth: '2rem', textAlign: 'center'}}>{possibility.coeff}</div>
                                            <button className={`btn btn-primary-${theme}`} disabled={rouletteRun} onClick={handleClickMoreCoeff}><FontAwesomeIcon icon="plus" /></button>
                                            <button className={`btn ms-2 btn-secondary-${theme}`} disabled={rouletteRun} onClick={handleClickDeletePossibility}><FontAwesomeIcon icon="xmark" /></button>
                                        </div>
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="modal fade" tabIndex="-1" id="winnerModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Et nous avons un vainqueur !</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p className="h1 text-center">{winner} !</p>
                            <img className="d-block mx-auto mt-5" src={process.env.PUBLIC_URL + "/images/roulette_winner.png"} alt="Vainqueur roulette"/>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-primary-${theme}`} data-bs-dismiss="modal">Retour</button>
                        </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" tabIndex="0" id="factoryModal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Réinitialiser toutes les roulettes ?</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Veux-tu vraiment réinitialiser toutes les roulettes par défaut ? Cela causera une perte de toutes tes roulettes créées.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className={`btn btn-primary-${theme}`} data-bs-dismiss="modal" onClick={handleFactoryRoulette}>Réinitialiser</button>
                            <button type="button" className={`btn btn-secondary-${theme}`} data-bs-dismiss="modal">Annuler</button>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Roulette;
