import React from "react";
import Menu from "./Menu";
import "../css/Thanks.css";
import Footer from "./Footer";

const Thanks = (props) => {
    return (
        <div id="remerciements">
            <div id="thanks-container">
                <div id="thanks-bg">
                    <div id="foglayer_01" className="fog d-none d-lg-block">
                        <div className="image01"></div>
                        <div className="image02"></div>
                    </div>
                    <div id="foglayer_02" className="fog d-none d-lg-block">
                        <div className="image01"></div>
                        <div className="image02"></div>
                    </div>
                    <div id="foglayer_03" className="fog d-none d-lg-block">
                        <div className="image01"></div>
                        <div className="image02"></div>
                    </div>
                </div>
                <Menu color="#d2a533" />
                <div className="thanks-content">
                    <p className="position-relative thanks-title">Pourquoi ce site existe ?</p>
                    <div className="row position-relative thanks-columns">
                        <div className="col-lg-8 col-md-12 mt-2">
                            <div className="thanks-column-card">
                                <h2>Football Manager & Arthur Ray</h2>
                                <p>
                                    Arthur Ray est un streamer, youtubeur, créateur de contenu principalement basé sur
                                    les jeux Football Manager.
                                </p>
                                <p>
                                    En avril 2023, il lance un nouveau concept : aider le club de sa ville, le FC
                                    Béziers, à monter en ligue 1 pour le monter dans les plus hautes sphères du ballon
                                    rond.
                                </p>
                                <p>
                                    Seulement, pour corser l'affaire, il sera confronté à deux équipes créées par ces
                                    viewers, l'Union Saint Michel et le Racing Club Olympignes. Grâce aux viewers, ces
                                    deux clubs bénéficieront de divers bonus tout au long de la carrière, pour empêcher
                                    Arthur d'atteindre le Graal qu'est la Ligue des Champions.
                                </p>
                                <p>Quelle équipe l'emportera ? Réponse sur le stream d'Arthur !</p>
                                <div className="thanks-column-clubs row">
                                    <div className="col-md-4 thanks-club">
                                        <img
                                            alt="Logo USM"
                                            className="thanks-club-img"
                                            src={process.env.PUBLIC_URL + "/images/Logo_USM.png"}
                                        />
                                        <p className="thanks-club-text">Union Saint-Michel</p>
                                    </div>
                                    <div className="col-md-4 thanks-club">
                                        <img
                                            alt="Logo USM"
                                            className="thanks-club-img"
                                            src={process.env.PUBLIC_URL + "/images/fc_beziers.png"}
                                        />
                                        <p className="thanks-club-text">FC Béziers</p>
                                    </div>
                                    <div className="col-md-4 thanks-club">
                                        <img
                                            alt="Logo USM"
                                            className="thanks-club-img"
                                            src={process.env.PUBLIC_URL + "/images/rco.jpg"}
                                        />
                                        <p className="thanks-club-text">Racing Club Olympigne</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 mt-2">
                            <div className="thanks-column-card">
                                <div className="thanks-arthur-profile">
                                    <h2>Retrouvez Arthur Ray sur tous ses réseaux !</h2>
                                    <div className="thanks-arthur-img"></div>
                                    <div className="thanks-arthur-links">
                                        <a href="https://www.youtube.com/c/ArthurRay" target="_blank" rel="noreferrer">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="96px"
                                                height="96px"
                                            >
                                                <path d="M21.582,6.186c-0.23-0.86-0.908-1.538-1.768-1.768C18.254,4,12,4,12,4S5.746,4,4.186,4.418 c-0.86,0.23-1.538,0.908-1.768,1.768C2,7.746,2,12,2,12s0,4.254,0.418,5.814c0.23,0.86,0.908,1.538,1.768,1.768 C5.746,20,12,20,12,20s6.254,0,7.814-0.418c0.861-0.23,1.538-0.908,1.768-1.768C22,16.254,22,12,22,12S22,7.746,21.582,6.186z M10,14.598V9.402c0-0.385,0.417-0.625,0.75-0.433l4.5,2.598c0.333,0.192,0.333,0.674,0,0.866l-4.5,2.598 C10.417,15.224,10,14.983,10,14.598z" />
                                            </svg>
                                        </a>
                                        <a href="https://twitter.com/rayurart" target="_blank" rel="noreferrer">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                                width="90px"
                                                height="90px"
                                            >
                                                <path d="M28,6.937c-0.957,0.425-1.985,0.711-3.064,0.84c1.102-0.66,1.947-1.705,2.345-2.951c-1.03,0.611-2.172,1.055-3.388,1.295 c-0.973-1.037-2.359-1.685-3.893-1.685c-2.946,0-5.334,2.389-5.334,5.334c0,0.418,0.048,0.826,0.138,1.215 c-4.433-0.222-8.363-2.346-10.995-5.574C3.351,6.199,3.088,7.115,3.088,8.094c0,1.85,0.941,3.483,2.372,4.439 c-0.874-0.028-1.697-0.268-2.416-0.667c0,0.023,0,0.044,0,0.067c0,2.585,1.838,4.741,4.279,5.23 c-0.447,0.122-0.919,0.187-1.406,0.187c-0.343,0-0.678-0.034-1.003-0.095c0.679,2.119,2.649,3.662,4.983,3.705 c-1.825,1.431-4.125,2.284-6.625,2.284c-0.43,0-0.855-0.025-1.273-0.075c2.361,1.513,5.164,2.396,8.177,2.396 c9.812,0,15.176-8.128,15.176-15.177c0-0.231-0.005-0.461-0.015-0.69C26.38,8.945,27.285,8.006,28,6.937z" />
                                            </svg>
                                        </a>
                                        <a href="https://www.twitch.tv/arthurrayyt" target="_blank" rel="noreferrer">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 24 24"
                                                width="96px"
                                                height="96px"
                                            >
                                                <path d="M 5.3632812 2 L 2 6.6367188 L 2 20 L 7 20 L 7 23 L 10 23 L 13 20 L 17 20 L 22 15 L 22 2 L 5.3632812 2 z M 6 4 L 20 4 L 20 13 L 17 16 L 12 16 L 9 19 L 9 16 L 6 16 L 6 4 z M 11 7 L 11 12 L 13 12 L 13 7 L 11 7 z M 16 7 L 16 12 L 18 12 L 18 7 L 16 7 z" />
                                            </svg>
                                        </a>
                                        <a
                                            href="https://www.instagram.com/xarthurray/?hl=fr"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 50 50" width="100px" height="100px">    <path d="M 16 3 C 8.83 3 3 8.83 3 16 L 3 34 C 3 41.17 8.83 47 16 47 L 34 47 C 41.17 47 47 41.17 47 34 L 47 16 C 47 8.83 41.17 3 34 3 L 16 3 z M 37 11 C 38.1 11 39 11.9 39 13 C 39 14.1 38.1 15 37 15 C 35.9 15 35 14.1 35 13 C 35 11.9 35.9 11 37 11 z M 25 14 C 31.07 14 36 18.93 36 25 C 36 31.07 31.07 36 25 36 C 18.93 36 14 31.07 14 25 C 14 18.93 18.93 14 25 14 z M 25 16 C 20.04 16 16 20.04 16 25 C 16 29.96 20.04 34 25 34 C 29.96 34 34 29.96 34 25 C 34 20.04 29.96 16 25 16 z"/></svg>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Thanks;
