import React from "react";
import Menu from "./Menu";
import "../css/Shop.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

const Shop = (props) => {
    const shopItems = [
        {
            id: 1,
            title: "Maillot Domicile",
            description: "Le meilleur moyen de supporter son club au Camp Moule.",
            image: process.env.PUBLIC_URL + "/images/domicile.png",
            prix: "69,99",
        },
        {
            id: 2,
            title: "Maillot Extérieur",
            description: "Pour porter fièrement nos couleurs en dehors du Mont-Saint-Michel.",
            image: process.env.PUBLIC_URL + "/images/exterieur.png",
            prix: "69,99",
        },
        {
            id: 3,
            title: "Maillot Third",
            description: "Un troisième maillot, pour une première place.",
            image: process.env.PUBLIC_URL + "/images/third.png",
            prix: "69,99",
            solde: "56,00",
        },
        {
            id: 4,
            title: "Nike x USM",
            description: "Supporter son équipe, c'est bien. Le faire avec style, c'est mieux.",
            image: process.env.PUBLIC_URL + "/images/nike_usm.png",
            prix: "129,99",
        },
        {
            id: 5,
            title: "Mercurial Superfly 9",
            description: "La paire des futurs champions de la LDC.",
            image: process.env.PUBLIC_URL + "/images/mercurial_usm.png",
            prix: "119,99",
        },
        {
            id: 6,
            title: "Couvre-chef du supporter",
            description: "Parce qu'en Normandie le soleil nous empêche de voir les matchs.",
            image: process.env.PUBLIC_URL + "/images/casquette.png",
            prix: "29,99",
        },
        
    ];

    return (
        <div id="boutique">
            <Menu />
            <div className="container">
                <header>
                    <div className="row header-line">
                        <div className="header-line-arthur col-lg-4">
                            <div className="header-arthur-container">
                                <p className="header-arthur-title">Saison 2022-2023</p>
                            </div>
                        </div>
                        <div className="header-line-side col-lg-8">
                            <div className="header-side-container">
                                <p className="header-side-title">Soldes</p>
                                <p className="header-side-desc">Jusqu'à -20% sur une sélection de produits</p>
                            </div>
                        </div>
                    </div>
                    <div className="header-foot">
                        <div className="header-foot-container">
                            <p>In Divitibus Credimus</p>
                        </div>
                    </div>
                </header>
                <p className="shop-title">Boutique</p>
                <div className="shop-content">
                    <ul className="shop-list row">
                        {shopItems.map((shopItem) => {
                            return (
                                <li className="col-lg-4 shop-item" key={shopItem.id}>
                                    <div className="shop-item-container">
                                        <p className="shop-item-title">{shopItem.title}</p>
                                        <p className="shop-item-desc">{shopItem.description}</p>
                                        <div
                                            className="shop-item-img-container"
                                            style={{ backgroundImage: `url('${shopItem.image}')` }}
                                        ></div>
                                        <div className="shop-item-footer">
                                            <div className={"shop-item-price " + (shopItem.solde ? "solde" : "")}>
                                                <div className="shop-real-price">{shopItem.prix} €</div>
                                                {shopItem.solde ? (
                                                    <div className="shop-solde">{shopItem.solde} €</div>
                                                ) : (
                                                    ""
                                                )}
                                            </div>
                                            <div className="shop-item-cart">
                                                Stock épuisé{" "}
                                                <FontAwesomeIcon className="shop-item-icon" icon={faXmark} />
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Shop;
