import React, { useState } from "react";
import Menu from "./Menu";
import "../css/Stadium.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShirt, faBeerMugEmpty, faCouch, faCrown, faMicrophone, faStar } from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";

const Stadium = (props) => {
    const [stadiumContent, setStadiumContent] = useState(
        <div className="interior-content">
            <p>Découvrez les différents espaces de votre stade favori.</p>
            <div
                className="stadium-part-image"
                style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/rayquestion.png"})` }}
            ></div>
        </div>
    );

    const [stadiumContentEmpty, setStadiumContentEmpty] = useState(true);

    const stadiumParts = [
        {
            id: 1,
            libelle: "Vestiaires",
            icon: faShirt,
            content: (
                <div className="interior-content">
                    <h2>Vestiaires</h2>
                    <p>
                        Totalement neufs, les vestiaires aux couleurs de l'USM offrent tout le matériel et le confort
                        pour les joueurs et le staff.
                    </p>
                    <div
                        className="stadium-part-image"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/locker.png"})` }}
                    ></div>
                </div>
            ),
        },
        {
            id: 2,
            libelle: "Buvette",
            icon: faBeerMugEmpty,
            content: (
                <div className="interior-content">
                    <h2>Buvette</h2>
                    <p>
                        Parce qu'un supporter rassasié est un supporter en pleine possession de ses moyens, l'atmosphère
                        de notre stade et la forme de nos supporters ont pour source principale notre légendaire
                        buvette, prête à tout pour satisfaire les clients les plus exigeants.
                    </p>
                    <div
                        className="stadium-part-image"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/buvette.png"})` }}
                    ></div>
                </div>
            ),
        },
        {
            id: 3,
            libelle: "Couloir des héros",
            icon: faStar,
            content: (
                <div className="interior-content">
                    <h2>Couloir des héros</h2>
                    <p>
                        C'est ici que les regards se croisent, que la tension monte. C'est dans ce couloir que nos
                        joueurs entendent les chants des supporters avant d'enter sur le terrain, dans le seul but
                        d'autant mouiller le maillot que lors d'une baignade dans la baie du Mont-Saint-Michel.
                    </p>
                    <div
                        className="stadium-part-image"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/entree.png"})` }}
                    ></div>
                </div>
            ),
        },
        {
            id: 4,
            libelle: "Carré d'or",
            icon: faCouch,
            content: (
                <div className="interior-content">
                    <h2>Carré d'or</h2>
                    <p>
                        Les meilleures places du stade, pour être au plus près de nos joueurs dans un confort jamais vu
                        ailleurs.
                    </p>
                    <div
                        className="stadium-part-image"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/carre_or.png"})` }}
                    ></div>
                </div>
            ),
        },
        {
            id: 5,
            libelle: "Tribune présidentielle",
            icon: faCrown,
            content: (
                <div className="interior-content">
                    <h2>Tribune présidentielle</h2>
                    <p>
                        Le Mont-Saint-Michel est attractif, le Camp Moule l'est tout autant. C'est pour cette raison
                        qu'il accueille des personnalités célèbres qui bénéficient d'un espace privé, à l'abri des
                        regards indiscrets.
                    </p>
                    <div
                        className="stadium-part-image"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/presidentielle.png"})` }}
                    ></div>
                </div>
            ),
        },
        {
            id: 6,
            libelle: "Salle d'interview",
            icon: faMicrophone,
            content: (
                <div className="interior-content">
                    <h2>Salle d'interview</h2>
                    <p>
                        Notre salle d'interview possède les dernières technologies en matière de vidéo et de son,
                        assurant aux médias des images dignes d'un cinéma, et aux joueurs et entraîneurs interviewés un
                        teint toujours au top.
                    </p>
                    <div
                        className="stadium-part-image"
                        style={{ backgroundImage: `url(${process.env.PUBLIC_URL + "/images/media_room.png"})` }}
                    ></div>
                </div>
            ),
        },
    ];

    return (
        <div id="stade">
            <div className="stade-container">
                <header>
                    <Menu color="#d2a533" />
                </header>
                <div className="stade-content row">
                    <div className="stade-title col-lg-7">
                        <h1>Le Camp Moule</h1>
                        <p>La Forteresse des Mers</p>
                    </div>
                    <div className="stade-text col-lg-5">
                        <div className="stade-text-content">
                            <h2>Un Lieu emblématique</h2>
                            <p>
                                Situé tout proche du monument le plus visité de France, le Camp Moule offre une vue
                                imprenable sur la célèbre abbaye du Mont-Saint-Michel. Avec une capacité d'environ 25
                                000 places, ce stade est un lieu de rassemblement pour les supporters locaux passionnés
                                de football. Les dirigeants de l'USM ont mis un point d'honneur à faire de cette
                                enceinte un lieu confortable pour les supporters, accompagnée d'une pelouse parfaitement
                                entretenue. Avec son atmosphère électrique et son architecture moderne, le Camp Moule
                                est l'un des stades les plus populaires de la région.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="stade-interior">
                <p className="stade-interior-title">Enceinte</p>
                <div className="stade-interior-text row">
                    <div className="col-sm-6 col-lg-3 order-2 order-lg-1 stade-interior-column stade-interior-column-left">
                        {stadiumParts.map((stadiumPart) => {
                            return stadiumPart.id <= 3 ? (
                                <button
                                    key={"stadium-part-" + stadiumPart.id}
                                    className="btn btn-primary-usm align-self-center"
                                    onClick={() => {
                                        setStadiumContent(stadiumPart.content);
                                        setStadiumContentEmpty(false);
                                    }}
                                >
                                    {stadiumPart.libelle}
                                    <FontAwesomeIcon icon={stadiumPart.icon} className="stadium-part-btn-icon" />
                                </button>
                            ) : (
                                ""
                            );
                        })}
                    </div>
                    <div
                        className={
                            "col-lg-6 order-1 order-lg-2 stade-interior-content " + (stadiumContentEmpty ? "empty" : "")
                        }
                    >
                        {stadiumContent}
                    </div>
                    <div className="col-sm-6 col-lg-3 order-3 order-lg-3 stade-interior-column stade-interior-column-right">
                        {stadiumParts.map((stadiumPart) => {
                            return stadiumPart.id > 3 ? (
                                <button
                                    key={"stadium-part-" + stadiumPart.id}
                                    className="btn btn-primary-usm align-self-center"
                                    onClick={() => {
                                        setStadiumContent(stadiumPart.content);
                                        setStadiumContentEmpty(false);
                                    }}
                                >
                                    {stadiumPart.libelle}
                                    <FontAwesomeIcon icon={stadiumPart.icon} className="stadium-part-btn-icon" />
                                </button>
                            ) : (
                                ""
                            );
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Stadium;
