import React from "react";
import Menu from "./Menu";
import "../css/Club.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFutbol,
    faShirt,
    faStar,
    faHeart,
    faArrowDownLong,
    faRightFromBracket,
} from "@fortawesome/free-solid-svg-icons";
import Footer from "./Footer";
import { Link } from "react-router-dom";

const Club = (props) => {
    const handleArrowDownClick = () => {
        document.getElementById("presentation").scrollIntoView({ behavior: "smooth" });
    };

    const navigationItems = [
        {
            desc: "Découvrez le Camp Moule, le stade emblématique de l'Union Saint-Michel.",
            id: 1,
            icon: faFutbol,
            image: process.env.PUBLIC_URL + "/images/stade.png",
            title: "Notre stade",
            link: "/stade",
        },
        {
            desc: "Ce pour quoi l'USM se bat cette saison, et celles à venir.",
            id: 2,
            icon: faStar,
            image: process.env.PUBLIC_URL + "/images/challenges.png",
            title: "Objectifs",
            link: "/objectifs",
        },
        {
            desc: "Devenez supporter de l'USM à temps complet avec nos tenues et accessoires.",
            id: 3,
            icon: faShirt,
            image: process.env.PUBLIC_URL + "/images/domicile.png",
            title: "Boutique",
            link: "/boutique",
        },
        {
            desc: "Sans qui tout ceci ne serait possible.",
            id: 4,
            icon: faHeart,
            image: process.env.PUBLIC_URL + "/images/arthurray.png",
            title: "Remerciements",
            link: "/remerciements",
        },
    ];

    return (
        <div id="club">
            <header>
                <Menu color="#d2a533" xColor="white" />
                <div id="header-container">
                    <div className="row">
                        <p className="header-devise">In Divitibus Credimus</p>
                    </div>
                    <div className="row flex-grow-1">
                        <div className="col-lg-5" id="header-logo">
                            <img
                                className="loaded"
                                alt="Logo USM"
                                src={process.env.PUBLIC_URL + "/images/Logo_USM.png"}
                            />
                        </div>
                        <div className="col-lg-7" id="header-text">
                            <p className="mb-5">Union Saint Michel</p>
                            <p>Héros des temps modernes</p>
                        </div>
                    </div>
                    <div className="row">
                        <FontAwesomeIcon
                            icon={faArrowDownLong}
                            id="header-arrow"
                            onClick={handleArrowDownClick}
                            size="3x"
                        />
                    </div>
                </div>
            </header>
            <section className="section-duo row" id="presentation">
                <div className="section-image col-lg-6 d-none d-lg-block" id="presentation-image"></div>
                <div className="section-text col-lg-6" id="presentation-text">
                    <h2>Le Club</h2>
                    <p>
                        Récemment établi dans la ville pittoresque du Mont-Saint-Michel, l'Union Saint-Michel est un
                        club de football situé dans la région de Normandie en France. Son origine remonte à mars 2023,
                        lorsque des passionnés de football membres de la communauté du streamer Arthur Ray ont décidé de
                        créer leur propre club.
                    </p>
                    <p>
                        Constatant l'absence d'un club de football représentant cette ville au niveau national, ses
                        fondateurs ont ainsi été motivés par la volonté de soutenir le développement du sport dans la
                        région, de donner une opportunité aux jeunes talents locaux de s'exprimer et de stimuler une
                        dynamique sportive locale.
                    </p>
                    <p>
                        Cette initiative est le fruit de la détermination et de l'engagement d'un groupe de fervents
                        amateurs de football, inspirés par une vision commune de renforcer la présence de leur ville sur
                        la scène sportive nationale.
                    </p>
                </div>
            </section>
            <section id="empty-background">
                <div id="empty-bg-text">
                    <p>Envie de découvrir la région ?</p>
                    <a
                        className="btn btn-transparent"
                        href="https://www.ot-montsaintmichel.com/"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Site du Mont Saint-Michel
                        <FontAwesomeIcon icon={faRightFromBracket} className="empty-bg-fa" />
                    </a>
                </div>
            </section>
            <section className="section-duo row" id="devise">
                <div className="section-text col-lg-6" id="devise-text">
                    <h2>Notre Devise</h2>
                    <p>
                        La devise du club, « In Divitibus Credimus », a été soigneusement choisie pour exprimer les
                        valeurs et les objectifs du club, en mettant l'accent sur l'union et la solidarité comme
                        vecteurs de richesse et de réussite. Cette phrase latine signifie « Nous croyons en la richesse
                        de l'union ».
                    </p>
                    <p>
                        Elle témoigne de la conviction du club selon laquelle la richesse ne se mesure pas uniquement en
                        termes financiers, mais également en termes de coopération et d'entraide. Cette devise souligne
                        ainsi l'importance de la collaboration et du travail d'équipe dans l'atteinte des objectifs
                        fixés.
                    </p>
                    <p>
                        En adoptant cette devise, le club manifeste également son engagement à jouer un rôle actif dans
                        la société. Le travail d'équipe est un élément clé pour réaliser les objectifs du club, mais
                        aussi pour contribuer au développement de la communauté locale.
                    </p>
                    <p>
                        Par le biais d'initiatives sociétales telles que des collectes de denrées alimentaires, des
                        événements pour lutter contre les discriminations, des opérations de nettoyage de l'espace
                        public, ainsi que l'organisation d'activités sportives pour les jeunes, le club s'efforce de
                        renforcer les liens sociaux et intergénérationnels dans la communauté locale.
                    </p>
                </div>
                <div className="section-image col-lg-6 d-none d-lg-block d-xl-block" id="devise-image"></div>
            </section>
            <section id="navigation">
                <p id="navigation-title">Navigation</p>
                <ul id="navigation-items" className="row">
                    {navigationItems.map((navigationItem) => {
                        const styles = {
                            backgroundImage: `url(${navigationItem.image})`,
                        };
                        return (
                            <li key={navigationItem.id} className="navigation-card col-lg-3 col-sm-6">
                                <Link to={navigationItem.link}>
                                    <div className="navigation-card-background background-image"/>
                                    <div className="navigation-card-content" style={styles} >
                                        <div className="navigation-card-info">
                                            <span className="navigation-card-title">{navigationItem.title}</span>
                                            <span className="navigation-card-desc">{navigationItem.desc}</span>
                                        </div>
                                        <div className="navigation-card-icon">
                                            <FontAwesomeIcon className="fa-icon" icon={navigationItem.icon} />
                                        </div>
                                    </div>
                                </Link>
                            </li>
                        );
                    })}
                </ul>
            </section>
            <Footer />
        </div>
    );
};

export default Club;
