import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./components/Home";
import Club from "./components/Club";
import Stadium from "./components/Stadium";
import Shop from "./components/Shop";
import Goals from "./components/Goals";
import Thanks from "./components/Thanks";
import Roulette from "./components/Roulette";
import {library} from '@fortawesome/fontawesome-svg-core';
import * as Icons from '@fortawesome/free-solid-svg-icons';

const iconList = Object.keys(Icons)
  .filter((key) => key !== 'fas' && key !== 'prefix')
  .map((icon) => Icons[icon]);

library.add(...iconList);

const App = () => {
    return (
        <Routes>
            <Route index element={<Home />} />
            <Route path="/club" element={<Club />} />
            <Route path="/stade" element={<Stadium />} />
            <Route path="/objectifs" element={<Goals />} />
            <Route path="/boutique" element={<Shop />} />
            <Route path="/remerciements" element={<Thanks />} />
            <Route path="/roulette" element={<Roulette />} />
            <Route path="*" element={<Home />} />
        </Routes>
    );
};

export default App;
