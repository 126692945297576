import * as React from "react";
import "../css/Menu.css";
import { Link } from "react-router-dom";

const handleClickMenu = () => {
    const menu = document.querySelector(".menu");
    const menuCut = document.querySelector(".menu-cut");
    const hamburgerMenu = document.querySelector(".hamburger-menu");
    const options = Array.from(document.querySelectorAll(".option"));
    menu.classList.toggle('active');
    menuCut.classList.toggle("active");
    hamburgerMenu.classList.toggle("active");
    options.forEach((option) =>
        option.addEventListener("click", () => {
            if (hamburgerMenu.classList.contains("active")) {
                menuCut.classList.remove("active");
                hamburgerMenu.classList.remove("active");
            }
        })
    );
};

const Menu = (props) => {
    const styleBurger = {
        backgroundColor: props.color ?? '',
    };

    const menuItems = [
        {
            id: 1,
            name: 'Accueil',
            link: '/',
        },
        {
            id: 2,
            name: 'Le Club',
            link: '/club',
        },
        {
            id: 3,
            name: 'Le Camp Moule',
            link: '/stade',
        },
        {
            id: 4,
            name: 'Objectifs',
            link: '/objectifs',
        },
        {
            id: 5,
            name: 'Boutique',
            link: '/boutique',
        },
        {
            id: 6,
            name: 'Remerciements',
            link: '/remerciements',
        },
        {
            id: 7,
            name: 'La Raylette',
            link: '/roulette',
        }
    ];

    const menuItemIsActive = (menuLink) => {
        return window.location.pathname === menuLink;
    }
    
    return (
        <div className="menu-header">
            <div id="menu-container">
                <div className="menu">
                    <div className="menu-cut">
                        <div className="menu-contents">
                            {
                                menuItems.map(menuItem => {
                                    return (
                                        <Link 
                                            key={menuItem.id} 
                                            to={menuItem.link} 
                                            className={'option ' + (menuItemIsActive(menuItem.link) ? 'active' : '')}
                                        >
                                            {menuItem.name}
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
                <div className="hamburger-menu" onClick={handleClickMenu}>
                    <div className="hamburger">
                        <div className="bar" style={styleBurger}></div>
                        <div className="bar" style={styleBurger}></div>
                        <div className="bar" style={styleBurger}></div>
                    </div>
                    <div className="x">
                        <div className="bar"></div>
                        <div className="bar"></div>
                    </div>
                </div>
            </div>
            { typeof props.noNav === 'undefined' &&
                <nav>
                    <ul className="menu-nav">
                        <li>
                            <img alt="Logo USM" src={process.env.PUBLIC_URL + "/images/Logo_USM.png"} height={40} />
                        </li>
                        {
                            menuItems.map(menuItem => {
                                return (
                                    <li key={menuItem.id}>
                                        <Link to={menuItem.link} className={'menu-item ' + (menuItemIsActive(menuItem.link) ? 'active' : '')}>
                                            {menuItem.name}
                                        </Link>
                                    </li>
                                );
                            })
                        }
                    </ul>
                </nav>
            }
            
        </div>
        
    );
};

export default Menu;
